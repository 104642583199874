<template>
	<div>
		<b-nav-item-dropdown class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
			<template #button-content>			
				<!-- <div :class="countNotifications > 0 ? 'bell' : ''">				 -->
					<feather-icon :badge="countNotifications" badge-classes="bg-danger" class="text-body" icon="MessageSquareIcon" size="21" />
				<!-- </div> -->
			</template>

			<!-- Header -->
			<li class="dropdown-menu-header">
				<div class="dropdown-header d-flex">
					<h4 class="notification-title mb-0 mr-auto">
						<!-- Notifications -->
					</h4>
					<b-button @click="showModalTasks" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm">
						<feather-icon icon="PlusIcon" /> Agregar
					</b-button>
					<!-- <b-badge pill variant="light-primary">
						6 New
					</b-badge> -->
				</div>
			</li>

			<!-- Notifications -->
			<vue-perfect-scrollbar :settings="perfectScrollbarSettings"
				class="scrollable-container media-list scroll-area" tagname="li">

				<!-- Tabs -->
				<b-tabs fill class="my-custom-tabs mt-0">
					<b-tab title="Tareas" key="tabTasks" id="tabTasks">
						<template #title>
							<span class="m-1">Tareas</span>
							<b-badge pill variant="warning">
								{{ tasks.length }}
							</b-badge>
						</template>

						<b-link v-for="(task, index) in tasks" :key="index + Math.random()" @click="showModal(task, true)">
							<b-media>
								<template #aside>
									<b-avatar size="32" :variant="task.type">
										<feather-icon icon="ClockIcon" />
									</b-avatar>
								</template>
								<p class="media-heading">
									<span class="font-weight-bolder">
										{{ task.title }}
									</span>
									<!-- <b-button @click="showModal(task, true)" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="float-right btn-icon ml-1" size="sm">
										Seguimiento
									</b-button> -->

									<b-button @click="completedTask(task)" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-warning" class="float-right btn-icon" size="sm">
										Finalizar
									</b-button>
								</p>
								<small class="notification-text" v-html="task.subtitle" /><br>
								<small class="timeline-item-time text-nowrap text-muted text-capitalize">{{ customFormatDate(task.created_at, 'lll') }} </small>
							</b-media>
							
						</b-link>
					</b-tab>
					<b-tab title="Avisos" key="tabNotifications" id="tabNotifications">
						<template #title>
							<span class="m-1">Avisos</span>
							<b-badge pill variant="primary">
								{{ notifications.length }}
							</b-badge>
						</template>

						<b-link v-for="notification in notifications" :key="notification.subtitle" @click="showModal(notification, false)">
							<b-media>
								<template #aside>
									<b-avatar size="32" :variant="notification.type">
										<feather-icon icon="MessageSquareIcon" />
									</b-avatar>
								</template>
								<p class="media-heading">
									<span class="font-weight-bolder">
									{{ notification.title }}
									</span>
								</p>
								<small class="notification-text" v-html="notification.subtitle	"/><br>
								<small class="timeline-item-time text-nowrap text-muted text-capitalize">{{ customFormatDate(task.created_at, 'lll') }} </small>
							</b-media>
						</b-link>
					</b-tab>
				</b-tabs>
			</vue-perfect-scrollbar>

			<!-- Cart Footer -->
			<!-- <li class="dropdown-menu-footer"><b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
					block>Read
					all
					notifications</b-button>
			</li> -->
		</b-nav-item-dropdown>

		<!-- Modal Seguimiento -->
		<b-modal ref="myModal" :size="existsComments ? 'lg' : 'md'" hide-footer :title="`${task.title}`">
			<b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
				<b-row v-show="isTask" class="match-height">
					<b-col cols="12" :lg="existsComments ? '6' : '12'" md="12">
						<h6><strong>Descripción:</strong> <span v-html="task.subtitle" /></h6>
						<b-row class="mt-1">
							<b-col cols="12">
								<b-form-group label="Comentarios" label-class="font-weight-3" label-for="comments">
									<b-form-textarea v-model="comment" id="comments" size="sm" placeholder="Ingrese un breve comentario"/>
								</b-form-group>
							</b-col>
						</b-row>
						<div class="text-right">
							<b-button size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary" @click="hideModal" class="m-1">
								Cerrar
							</b-button>
						
							<b-button :disabled="loading" @click="completedTask(task)" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger">
								Finalizar Tarea
							</b-button>

							<b-button :disabled="loading" @click="addCommentTask(task)" size="md" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
							class="m-1">
								Guardar
							</b-button>							
						</div>
					</b-col>

					<b-col cols="12" :lg="existsComments ? '6' : '12'" md="12">
						<vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area" tagname="div">
							<app-timeline v-show="existsComments" class="mt-2_">							
								<app-timeline-item v-for="(item, index) in task.comments" :key="index" :fill-border="true"
								:variant="index % 2 === 0 ? 'primary' : 'info'">
									<div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
										<small class="text-muted text-capitalize">{{ dateTimeFormat(item.created_at) }}</small>
									</div>
									<small>{{ item.content }}</small>
									<b-media class="mt-1">
										<template #aside>
											<b-avatar size="24" :src="null"
												:text="avatarText2(item.user_name)" />
										</template>
										<small>{{ item.user_name }}</small>
									</b-media>
								</app-timeline-item>
							</app-timeline>
						</vue-perfect-scrollbar>
					</b-col>
				</b-row>
				<b-row v-show="!isTask">
					<b-col cols="12" md="12">
						<b-row>
							<b-col cols="12">
								<b-form-group label="Descripción" label-for="description">
									<b-form-textarea readonly v-model="task.subtitle" id="description" size="sm" placeholder="Ingrese una descripción"/>
								</b-form-group>
							</b-col>
						</b-row>
						<div class="text-right">
							<b-button size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="m-1" variant="outline-secondary" @click="hideModal">
								Cerrar
							</b-button>
							<b-button v-if="task.user_name === user.userName" :disabled="loading" @click="deleteNotification(task)" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger">
								Eliminar
							</b-button>
						</div>
					</b-col>
				</b-row>
			</b-overlay>
		</b-modal>

		<b-modal ref="myModalTasks" hide-footer title="Agregar Tarea / Avisos">
			<b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
				<b-row>
					<b-col cols="12">
						<b-form-radio-group
							v-model="optionSelected"
							:options="[
								{ text: 'Tarea', value: 'tasks', },
								{ text: 'Aviso', value: 'notifications', },
							]"
							class="demo-inline-spacing mb-3"
							value-field="value"
							text-field="text"
							disabled-field="disabled"
						/>
					</b-col>
					<b-col cols="12">
						<b-form-group label="Título" label-for="title">
							<b-form-input v-model="task.title" id="title" size="sm" placeholder="Ingrese un título"/>
						</b-form-group>
					</b-col>
					<b-col cols="12">
						<b-form-group label="Descripción" label-for="description">
							<!-- <b-form-textarea v-model="task.subtitle" id="description" size="sm" placeholder="Ingrese una descripción"/> -->
							<quill-editor
								v-model="task.subtitle"
								:options="editorOptions"
							
							/>
						</b-form-group>
					</b-col>
				</b-row>
				<div class="text-right">
					<b-button size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="m-1" variant="outline-secondary" @click="hideModalTasks">
						Cerrar
					</b-button>
					<b-button @click="addTask" :disabled="loading" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
						Guardar
					</b-button>
				</div>
			</b-overlay>
		</b-modal>
	</div>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import {
	BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
	BTabs, BTab, BModal, BForm, BFormGroup, BFormInput, BFormTextarea, BCard, BCardBody, BCardHeader, BRow, BCol,
	BOverlay, BFormRadioGroup
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { computed, nextTick, onMounted, ref } from '@vue/composition-api'
import * as helper from '@/libs/helpers';
import Swal from "sweetalert2";
import "animate.css";
import moment from 'moment'
import { isAuthenticated } from '@/libs/authHelper';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { quillEditor } from "vue-quill-editor";

export default {
	name: 'NotificationDropdown',
	components: {
		BNavItemDropdown,
		BBadge,
		BMedia,
		BLink,
		BAvatar,
		VuePerfectScrollbar,
		BButton,
		BFormCheckbox,
		BTabs,
		BTab,
		BModal,
		BFormInput,
		BForm, 
		BFormGroup, 
		BFormTextarea,
		BCard, 
		BCardBody, 
		BCardHeader, 
		BRow, 
		BCol,
		BOverlay,
		BFormRadioGroup,

		//
		AppTimeline,
		AppTimelineItem,
		quillEditor,
	},
	directives:{
		Ripple,
	},
	data: () => ({
		logo: require('@/assets/images/logo/ferreysa.png'),
		logo_1: require('@/assets/images/logo/ferreysa_1.jpg'),
		logo_2: require('@/assets/images/logo/ferreysa_2.jpg'),
		logo_3: require('@/assets/images/logo/ferreysa_3.jpg'),
		editorOptions: {
			modules: {
				toolbar: false,				
				// toolbar: [
				// 	["bold", "italic", "underline", "strike"],
				// 	["blockquote", "code-block"],
				// 	[{ header: [1, 2, 3, 4, 5, 6, false] }],
				// 	[{ list: "ordered" }, { list: "bullet" }],
				// 	[{ indent: "-1" }, { indent: "+1" }],
				// 	[{ color: [] }, { background: [] }],
				// 	[{ align: [] }],
				// 	[{ size: ["small", false, "large", "huge"] }],
				// 	[{ font: [] }],
				// 	["clean"],
				// ],
			},
			placeholder: "Escribe aquí...",
		},
	}),
	created() {
		this.listenForTaskCreated()	
	},
	mounted() {
		this.listenForTaskCreated()		
    },
	methods: {
		listenForTaskCreated() {
			window.Echo.channel('tasks-channel')
			.listen('TaskCreated', async (event) => {
				// Verifica si el permiso ya está concedido para evitar pedirlo cada vez
				// if (Notification.permission === 'granted') {
				// 	this.showNotification(event.message);
				// } else {
				// 	Notification.requestPermission(permission => {
				// 		if (permission === 'granted') {
				// 			this.showNotification(event.message);
				// 		}
				// 	});
				// }
				
				// Actualiza las tareas solo si es necesario
				await this.getTasks();
			});
		},

		// Función para mostrar la notificación
		showNotification(message) {
			const title = `${message.model === 'tasks' ? 'Tarea' : 'Aviso'} Ferreysa`;

			let notification = new Notification(title, {
				body: message.subtitle,
				icon: this.logo, // Asegúrate de que sea una URL válida
			});

			// notification.onclick = () => {
			// 	// Aquí puedes agregar lógica para redirigir al usuario
			// 	// o realizar alguna acción al hacer clic en la notificación
			// 	window.open(window.location.href); // Ejemplo para redirigir al mismo lugar
			// };
		},
		
		showModalTasks() {
			this.task = {}
			this.$refs['myModalTasks'].show()
		},
		hideModalTasks() {
			this.task = {}
			this.$refs['myModalTasks'].hide()
		},
		showModal(item, isTask) {
			
			this.task = {}
			this.task = item
			this.isTask = isTask			
			this.$refs['myModal'].show()
		},
		hideModal() {
			this.task = {}
			this.$refs['myModal'].hide()
		},
		toggleModal() {
			// We pass the ID of the button that we want to return focus to
			// when the modal has hidden
			this.$refs['myModal'].toggle('#toggle-btn')
		},
	},
	setup() {

		const tasks = ref([])
		const loading = ref(false)
		const task = ref({})
		const comment = ref('')	
		const myModalTasks = ref(null)
		const myModal = ref(null)
		const optionSelected = ref('tasks')
		const isTask = ref(true)

		const notifications = ref([])		

		const perfectScrollbarSettings = {
			maxScrollbarLength: 60,
			wheelPropagation: false,
		}

		const countNotifications = computed(() => notifications.value.length + tasks.value.length)
		const existsComments = computed(() => task.value.comments && task.value.comments.length > 0)
		const user = computed(() => store.getters['auth/getUser'])

		const loginInCloud = async () => {
			console.log('Iniciando sesión en la nube... DESDE notificationDropdown.vue')
			const payload = {
				grant_type: process.env.VUE_APP_API_PRICES_GRANT_TYPE,
				client_id: process.env.VUE_APP_API_PRICES_CLIENT_ID,
				client_secret: process.env.VUE_APP_API_PRICES_CLIENT_SECRET,
				scope: process.env.VUE_APP_API_PRICES_SCOPE,
			}
			try {			
				const { data } = await axios.post(`${process.env.VUE_APP_API_PRICES}/oauth/token`, payload, {
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
				});				

				if (data) {
					store.commit('auth/SET_TOKEN_CLOUD', data)
					localStorage.setItem('token_cloud', data.access_token)
				}
			}catch(e) {
				console.log(e)
			}
		}

		const addCommentTask = async (item) => {

			if (!comment.value) {
				helper.danger('El comentario es requerido')
				return
			}

			// Verifica si el usuario está autenticado
			if (!isAuthenticated()) {
				await loginInCloud() // Realiza el login si no está autenticado
			}

			loading.value = true
			const payload = {
				comment: comment.value,
				user_name: user.value.userName,
			}
			
			try {
				const { data } = await axios.put(`${process.env.VUE_APP_API_PRICES}/api/client/tasks/${item.id}`, payload, {
					headers: {
						Authorization: `Bearer ${store.getters['auth/getTokenCloud'].access_token}`,
						Accept: 'application/json',
					},
				})
				
				if (data.success) {
					helper.success('Comentario agregado correctamente')
					task.value.comments = data.data.comments
					comment.value = ''
				}				

			}catch(e) {
				console.error(e)
				helper.handleResponseErrors(e)
			} finally {
				loading.value = false
			}
		}

		const deleteNotification = async (item) => {
			Swal.fire({
				title: `¿Desea continuar
				?`,
				html: `Se ejecutará un proceso para eliminar la tarea <strong>${item.title}</strong>.`,
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1",
				},
				showClass: {
					popup: "animate__animated animate__flipInX",
				},
				buttonsStyling: false,
				showLoaderOnConfirm: true,
				preConfirm: async () => {
					loading.value = true
					try {
						const { data } = await axios.delete(`${process.env.VUE_APP_API_PRICES}/api/client/tasks/${item.id}`, {
							headers: {
								Authorization: `Bearer ${store.getters['auth/getTokenCloud'].access_token}`,
								Accept: 'application/json',
							},
						})
						
						if (data.success) {
							helper.success('aviso eliminada correctamente')
							notifications.value = notifications.value.filter(t => t.id !== item.id)

							if (myModal.value) myModal.value.hide() // Oculta el modal
						}				

					}catch(e) {
						console.error(e)
						helper.handleResponseErrors(e)
					} finally {
						loading.value = false
					}		
				},
				allowOutsideClick: () => !Swal.isLoading(),
			})
		}

		const addTask = async () => {

			// Verifica si el usuario está autenticado
			if (!isAuthenticated()) {
				await loginInCloud() // Realiza el login si no está autenticado
			}

			loading.value = true

			try {
				const { data } = await axios.post(`${process.env.VUE_APP_API_PRICES}/api/client/tasks`, {
					title: task.value.title,
					subtitle: task.value.subtitle,
					type: 'light-primary',
					model: optionSelected.value,
					user_name: user.value.userName,
				}, {
					headers: {
						Authorization: `Bearer ${store.getters['auth/getTokenCloud'].access_token}`,
						Accept: 'application/json',
					},
				})
				
				if (data.success) {
					
					if (optionSelected.value === 'tasks') {
						helper.success('Tarea agregada correctamente')
						tasks.value.unshift(data.data) //Va al inicio
					} else {
						helper.success('aviso agregada correctamente')
						notifications.value.unshift(data.data) //Va al inicio
					}
					
					task.value = {}
					optionSelected.value = 'tasks'

					if (myModalTasks.value) myModalTasks.value.hide() // Oculta el modal
				}			

			}catch(e) {
				console.error(e)
				helper.handleResponseErrors(e)
			} finally {
				loading.value = false
			}
		}

		const completedTask = async (item) => {
			Swal.fire({
				title: `¿Desea continuar?`,
				html: `Se ejecutará un proceso para finalizar la tarea <strong>${item.title}</strong>.`,
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1",
				},
				showClass: {
					popup: "animate__animated animate__flipInX",
				},
				buttonsStyling: false,
				showLoaderOnConfirm: true,
				preConfirm: async () => {
					loading.value = true
					try {
						const { data } = await axios.put(`${process.env.VUE_APP_API_PRICES}/api/client/tasks/${item.id}`, {
							status: 'completed',
						}, {
							headers: {
								Authorization: `Bearer ${store.getters['auth/getTokenCloud'].access_token}`,
								Accept: 'application/json',
							},
						})
						
						if (data.success) {
							helper.success('Tarea completada correctamente')
							tasks.value = tasks.value.filter(t => t.id !== item.id)
							if (myModal.value) myModal.value.hide() // Oculta el modal
						}				

					}catch(e) {
						console.error(e)
						helper.handleResponseErrors(e)
					} finally {
						loading.value = false
					}		
				},
				allowOutsideClick: () => !Swal.isLoading(),
			})
		}

		const getTasks = async () => {
			tasks.value = []
			loading.value = true

			// Verifica si el usuario está autenticado
			if (!isAuthenticated()) {
				await loginInCloud() // Realiza el login si no está autenticado
			}

			try {
				// Realiza la solicitud de tareas después de que el usuario haya sido autenticado
				const { data } = await axios.get(`${process.env.VUE_APP_API_PRICES}/api/client/tasks?status=pending`, {
					headers: {
						Authorization: `Bearer ${store.getters['auth/getTokenCloud'].access_token}`,
						Accept: 'application/json',
					},
				})

				if (data.success) {
					tasks.value = data.data.filter(t => t.model === 'tasks')
					notifications.value = data.data.filter(t => t.model === 'notifications')
				}
			} catch (e) {
				console.error(e)
			} finally {
				loading.value = false
			}

			// console.log('autenticado', isAuthenticated())
		}

		const readNotification = async (item) => {
			console.log(item)
			Swal.fire({
				title: `¿Desea continuar?`,
				html: `Se ejecutará un proceso para marcar como leída la notificación <strong>${item.title}</strong>.`,
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1",
				},
				showClass: {
					popup: "animate__animated animate__flipInX",
				},
				buttonsStyling: false,
				showLoaderOnConfirm: true,
				preConfirm: async () => {
					loading.value = true
					try {
						const { data } = await axios.put(`${process.env.VUE_APP_API_PRICES}/api/client/tasks/${item.id}`, {
							status: 'completed',
						}, {
							headers: {
								Authorization: `Bearer ${store.getters['auth/getTokenCloud'].access_token}`,
								Accept: 'application/json',
							},
						})
						
						if (data.success) {
							helper.success('Notificación marcada como leída correctamente')
							notifications.value = notifications.value.filter(t => t.id !== item.id)
						}				

					}catch(e) {
						console.error(e)
						helper.handleResponseErrors(e)
					} finally {
						loading.value = false
					}		
				},
				allowOutsideClick: () => !Swal.isLoading(),
			})
		}

		onMounted(async () => {
			await getTasks()
		})

		return {
			loading,
			notifications,			
			countNotifications,
			comment,
			myModalTasks,
			optionSelected,
			myModal,

			existsComments,
			perfectScrollbarSettings,
			tasks,
			task,
			isTask,
			user,

			//
			getTasks,
			deleteNotification,
			readNotification,
			completedTask,
			addTask,
			addCommentTask,
		}
	},
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}
#quil-content
  #quill-reminder-content
  #quill-reminder-body-content
  #quill-reminder-footer-content
  #quill-body-content
  #quil-footer-content
  ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }
  + #quill-toolbar
    #quil-body-toolbar
    #quil-footer-toolbar
    #quill-reminder-toolbar
    #quil-reminder-body-toolbar
    #quil-reminder-footer-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
