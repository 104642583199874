<template>
    <div>
        <b-modal
                ref="infoModalRef"
                id="modalPurchaseCustomize"
                title="Parámetros de Cálculo"
                no-close-on-backdrop
                hide-footer
                @cancel="isActive = false"
                @hidden="closeModal"
                :visible="isActive"
            >
            

            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <b-form class="p-2" ref="formRef" @submit.prevent="handleSubmit(updateSettingPurchase)" @reset.prevent="resetForm">
                    <b-row>
                        <b-col cols="6">
                            <validation-provider #default="validationContext" name="Compra Desde"
                                rules="required">
                                <b-form-group label="Compra Desde" label-for="buy_from">
                                    <flat-pickr id="buy_from" v-model="calculateParameters.buy_from" class="form-control"
                                        :config="{ altInput: true, dateFormat: 'Y-m-d', }" placeholder="DD/MM/YYYY"
                                        :state="getValidationState(validationContext)"/>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col cols="6">
                            <validation-provider #default="validationContext" name="Precio Bajo"
                                rules="required">
                                <b-form-group label="Precio Bajo" label-for="low_price">
                                    <b-form-input  id="low_price" v-model="calculateParameters.low_price" type="number"
                                        placeholder="Precio Bajo"
                                        :state="getValidationState(validationContext)" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col cols="6">
                            <validation-provider #default="validationContext" name="Mes Bajo"
                                rules="required">
                                <b-form-group label="Mes Bajo" label-for="low_month">
                                    <b-form-input  id="low_month" v-model="calculateParameters.low_month" type="number"
                                        placeholder="Mes Bajo"
                                        :state="getValidationState(validationContext)" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col cols="6">
                            <validation-provider #default="validationContext" name="Precio Alto"
                                rules="required">
                                <b-form-group label="Precio Alto" label-for="high_price">
                                    <b-form-input  id="high_price" v-model="calculateParameters.high_price" type="number"
                                        placeholder="Precio Alto"
                                        :state="getValidationState(validationContext)" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col cols="6">
                            <validation-provider #default="validationContext" name="Mes Alto"
                                rules="required">
                                <b-form-group label="Mes Alto" label-for="high_month">
                                    <b-form-input  id="high_month" v-model="calculateParameters.high_month" type="number"
                                        placeholder="Mes Alto"
                                        :state="getValidationState(validationContext)" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col cols="6">
                            <validation-provider #default="validationContext" name="Mes Medio"
                                rules="required">
                                <b-form-group label="Mes Medio" label-for="high_month">
                                    <b-form-input  id="middle_month" v-model="calculateParameters.middle_month" type="number"
                                        placeholder="Mes Medio"
                                        :state="getValidationState(validationContext)" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                    </b-row>
                </b-form>
            </validation-observer>

            <div class="text-right mt-2">
                <b-button size="sm" :disabled="loading" @click="updateSettingPurchase" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                    class="mr-2" type="button">
                    Guardar
                </b-button>
                <b-button size="sm" :disabled="loading" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button"
                    variant="outline-secondary" @click="closeModal">
                    Cancelar
                </b-button>
            </div>

        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    BCol,
    BRow,
    BMedia,
    BAvatar,
    BFormCheckbox,
    BInputGroup,
    BLink,
    BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { ref, computed, onMounted, watch, reactive, defineEmits } from "@vue/composition-api";
import store from '@/store';
import * as helper from '@/libs/helpers';
import moment from 'moment';
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";

export default {
    components: {
        BButton,
        BModal,
        BForm,
        BFormInput,
        BFormGroup,
        BFormInvalidFeedback,
        BCol,
        BRow,
        BMedia,
        BAvatar,
        BFormCheckbox,
        BInputGroup,
        BLink,
        BInputGroupAppend,

        //
        flatPickr,

        //
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        "b-modal": VBModal,
        Ripple,
    },
    props: {
        isActive: {
            type: Boolean,
            required: true,
            default: () => false,
        },       
    },
    created() {
        flatpickr.localize(Spanish);
        localize('es')
    },
    setup(props, { emit }) {
        
        const loading = ref(false)
        const infoModalRef = ref(null);
        const selected = ref([])
        const calculateParameters = ref({
            buy_from: moment().add(-6, 'months').format('YYYY-MM-DD'),
            low_price: 5,
            low_month: 4,
            high_price: 1000,
            high_month: 1,
            middle_month: 2.5,
        })

        const closeModal = () => {
            infoModalRef.value.hide();          
            emit("close");
        };

        const setting = computed(() => {
            return store.getters['auth/getSettingPurchase']
        })

        const updateSettingPurchase = () => {
            setting.value.buy_from = calculateParameters.value.buy_from            
            setting.value.low_price = calculateParameters.value.low_month
            setting.value.high_price = calculateParameters.value.high_price
            setting.value.high_month = calculateParameters.value.high_month
            setting.value.middle_month = calculateParameters.value.middle_month
            store.dispatch('auth/UPDATE_SETTING_PURCHASE', setting.value)
            helper.success(`Configuración de compras actualizada con éxito`, '¡Éxito!');
            closeModal()
        }

        const { refFormObserver, getValidationState, resetForm } =
            formValidation(calculateParameters);

        return {
            loading,
            infoModalRef,
            closeModal,
            setting,
            selected,
            calculateParameters,

            //
            refFormObserver,
            getValidationState,
            resetForm,
            updateSettingPurchase
        }
    },
}
</script>