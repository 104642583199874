// src/utils/authHelper.js
import moment from 'moment';
import store from '@/store';

export function isAuthenticated() {
    const tokenData = store.getters['auth/getTokenCloud'];
    if (!tokenData || !tokenData.access_token) return false;

    let expiresAt;
    if (!tokenData.expires_at) {
        const issuedAt = moment.utc(tokenData.issued_at);
        const duration = tokenData.expires_in;
        expiresAt = issuedAt.add(duration, 'seconds');
    } else {
        expiresAt = moment.utc(tokenData.expires_at);
    }

    const nowUtc = moment.utc();
    return expiresAt.isAfter(nowUtc);
}